import React from 'react';

const Checkmark = () => (
  <svg viewBox="0 0 24 24" width="1em" height="1em">
    <defs>
      <path
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zm5 5.5c-.2-.2-.5-.1-.7.1l-5.9 7.5-2.9-3c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l3.3 3.4c.1.1.3.2.4.2h.063c.16-.004.248-.033.337-.3l6.2-7.9c.2-.2.1-.5-.1-.7z"
        id="svg-checkmark-a"
      />
    </defs>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <mask id="svg-checkmark-b" fill="#fff">
        <use xlinkHref="#svg-checkmark-a" />
      </mask>
      <use
        fill="currentColor"
        fillRule="nonzero"
        xlinkHref="#svg-checkmark-a"
      />
      <g mask="url(#svg-checkmark-b)" fill="currentColor">
        <path d="M-1.09-1.09v21.817h21.817V-1.09z" />
      </g>
    </g>
  </svg>
);

export default Checkmark;
